<template>
    <div class="container referals p-0">
        <div class="history-order__wrapp">
            <div class="history-order__item">
                <div class="history-order__head">
                    Додати Тариф
                </div>
                <div class="history-order__form-group">
                    <div :class="{'is-invalid': hasError('companyId')}" class="history-order__form-heading">Компанія: <span style="font-weight: 300"> {{ tariff.companyName }}</span></div>
                    <div v-if="hasError('companyId')" class="invalid-feedback">
                        {{ getError('companyId') }}
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <label>Тип користувача</label>
                            <select v-model="tariff.userType" class="form-control">
                                <option v-for="type in userTypesList" :key="type.id" :value="type.id">{{type.name}}</option>
                            </select>
                        </div>
                        <div class="col-md-4">
                            <label>Тип ОТК</label>
                            <select v-model="tariff.otkType" class="form-control">
                                <option v-for="type in otkTypes" :key="type.id" :value="type.id">{{type.name}}</option>
                            </select>
                        </div>
                        <div class="col-md-4">
                            <label>Тип ТЗ</label>
                            <select v-model="tariff.autoCategoryType" class="form-control">
                                <option v-for="type in autoCategoryTypes" :key="type.id" :value="type.id">{{ type.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <label>Зона реєстрації</label>
                            <select v-model="tariff.zone" class="form-control">
                                <option v-for="type in zoneTypes" :key="type.id" :value="type.id">{{ type.name }}</option>
                            </select>
                        </div>
                        <div class="col-md-4">
                            <label>Франщиза</label>
                            <select v-model="tariff.franchise" class="form-control">
                                <option v-for="type in franchiseTypes" :key="type.id" :value="type.id">{{ type.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="history-order__form-heading">Комісія партнерів (%):</div>
                    <div class="row">
                        <div  v-if="! isLandingUserType(partner.typeId)" v-for="partner in partnersList" :class="{'is-invalid': hasError('userCommission')}" class="col-md-6">
                            <label>{{ partner.fullName }}</label>
                            <input autocomplete="off" :class="{'is-invalid': hasError('userCommission.' + partner.id)}" v-mask="'##'" v-model="tariff.userCommission[`${partner.id}`]"  class="form-control">
                            <div v-if="hasError('userCommission.' + partner.id)" class="invalid-feedback">
                                {{ getError('userCommission.' + partner.id) }}
                            </div>
                        </div>
                        <div v-if="hasError('userCommission')" class="invalid-feedback">
                            {{ getError('userCommission') }}
                        </div>
                    </div>
                    <div class="history-order__form-heading">Знижки партнерів (%):</div>
                    <div class="row">
                        <div v-if="! isLandingUserType(partner.typeId)" v-for="partner in partnersList" :class="{'is-invalid': hasError('userCommission')}" class="col-md-6">
                            <label>{{ partner.fullName }}</label>
                            <input autocomplete="off" :class="{'is-invalid': hasError('usersDiscount.' + partner.id)}" v-mask="'##'" v-model="tariff.usersDiscount[`${partner.id}`]"  class="form-control">
                            <div v-if="hasError('usersDiscount.' + partner.id)" class="invalid-feedback">
                                {{ getError('usersDiscount.' + partner.id) }}
                            </div>
                        </div>
                        <div v-if="hasError('usersDiscount')" class="invalid-feedback">
                            {{ getError('usersDiscount') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="partner-order__nav">
                <div class="row">
                    <div class="col-md-4">
                        <button type="button" @click="save()" class="btn-blue-w">Зберегти </button>
                    </div>
                    <div class="col-md-4">

                        <router-link :to="{ name: 'products' }">
                            <button class="btn-grey">Повернутися назад</button>
                        </router-link>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { userMixin } from '@/mixin';
import { createUserTariff } from '../../api/tariff';

export default {
    name: 'addTariff',
    mixins: [userMixin],
    data() {
        return {
            errors: null,
            tariff: {
                userCommission: {},
                usersDiscount: {},
                usersCashBack: {},
                zone: null,
                otkType: null,
                userType: null,
                franchise: null,
                companyId: null,
                companyType: null,
                companyName: '',
                autoCategoryType: null,
            },
        }
    },

    created() {
        if (this.$store.getters.GET_NEW_TARIFF.companyId) {
            this.tariff.companyId = this.$store.getters.GET_NEW_TARIFF.companyId;
            this.tariff.companyType = this.$store.getters.GET_NEW_TARIFF.companyType;
            this.tariff.companyName = this.$store.getters.GET_NEW_TARIFF.companyName;
        } else {
            this.$router.push({name: 'products'});
        }
    },

    serverPrefetch() {
        return this.fetchPartnersList();
    },

    computed: {
        partnersList() {
            return this.$store.getters.ALL_PARTNERS;
        },
        franchiseTypes() {
            return this.$store.getters.FRANCHISE_LIST;
        },
        zoneTypes() {
            return this.$store.getters.ZONE_TYPES;
        },
        userTypesList() {
            return this.$store.getters.USER_TYPES_TARIFF_LIST;
        },
        otkTypes() {
            return this.$store.getters.OTK_TYPES_LIST;
        },
        autoCategoryTypes() {
            return this.$store.getters.AUTO_CATEGORIES_LIST;
        }
    },

    mounted() {
        if (! this.partnersList) {
            this.fetchPartnersList();
        }
    },
    methods: {
        save() {
            this.errors = null;

            createUserTariff(this.tariff)
                .then(response => {
                    if (response.data.data == true) {
                        this.$alert('Тариф удачно добавлен');
                        this.$router.push({name: 'products'});

                        return true;
                    }
                })
                .catch(e => {
                    this.errors = e.response.data.data;
                    return false;
                });
        },
        fetchPartnersList() {
            return this.$store.dispatch('GET_ALL_PARTNERS');
        },
        getError(key) {
            return this.errors[key][0]; //return first error
        },
        hasError(key) {
            return _.has(this.errors, key);
        },
    }
}
</script>
